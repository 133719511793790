import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const LANGUAGES = [
    {
      alias: 'es',
      name: 'Español',
      imagePath: 'es.png',
    },
    {
      alias: 'en',
      name: 'English',
      imagePath: 'en.png',
    }
  ];

  const [showLangs, setShowLangs] = useState(false);
  const [currentLang, setCurrentLang] = useState();

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  }

  useEffect(() => {
    const current = LANGUAGES.find(l => l.alias === i18n.language);

    if (!current) return;
    setCurrentLang(current)
  }, [i18n.language]);

  return (
    <>
      {currentLang && <button className="lenguageBtn btn btnCurrent" onClick={() => setShowLangs(!showLangs)}>
        <span class="lenguageNewText">
          { currentLang.alias.toUpperCase() }
        </span>
        <img class="lenguageNewImg" src={`./${currentLang.imagePath}`} alt={currentLang.name} />
      </button>}
      <ul className={showLangs ? 'show' : 'noShow'}>
        {LANGUAGES.map(lang => (
          <li>
          <button
            key={`lang_${lang}`}
            className="lenguageBtn btn"
            id={lang.alias}
            onClick={() => {
              handleChangeLanguage(lang.alias)
              setShowLangs(!showLangs)
            }}>
            <span class="lenguageNewText">
              { lang.alias.toUpperCase() }
            </span>
            <img class="lenguageNewImg" src={`./${lang.imagePath}`} alt={lang.name} />
          </button>
          </li>
        ))}
      </ul>
    </>
  )
}