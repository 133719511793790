import React from "react";
import Layout from "./components/Layout/Layout";
import Landing from "./components/Page/landing";
import "./App.css";
import "./media-queries.css";
import "./vars.css";

function App() {
	return (
		<>
			<Layout>
				<Landing />
			</Layout>
		</>
	);
}

export default App;
