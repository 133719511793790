import React, { useState, useEffect } from 'react';
import { LanguageSelector } from './LanguageSelector';
import { useTranslation } from "react-i18next";

const TikrayHeader = () => {
    const { t } = useTranslation();

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const emailText = 'comercial@mytikray.com';
    const phoneText = '+51905434422';

    const [navIsOpen, setNavIsOpen] = useState(false);

    const menuMobileModal = (open) => {
        setNavIsOpen(open);
    };

    const [scrolled, setScrolled] = useState(false);

    const copyToClipboard = (text) => {
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
    };

    const handleEmailCopyMobile = () => {
        copyToClipboard(emailText);
    };

    const handlePhoneCopyMobile = () => {
        copyToClipboard(phoneText);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header id="header" className={scrolled ? 'scrolled' : ''}>
            <button class="btnLogo btn" id="logoMain">
                <a href="#home">
                    <svg width="178" height="59" viewBox="0 0 178 59" fill="transparent">
                        <g>
                            <path
                                d="M27.7907 5.48109H2.4469C1.09499 5.48109 0 6.56941 0 7.91308V10.6308C0 11.9745 1.09499 13.0628 2.4469 13.0628H10.3565V41.9001C10.3565 43.2438 11.4515 44.3321 12.8034 44.3321H17.4311C18.783 44.3321 19.878 43.2438 19.878 41.9001V13.0628H27.7876C29.1396 13.0628 30.2346 11.9745 30.2346 10.6308V7.91308C30.2346 6.56941 29.1396 5.48109 27.7876 5.48109H27.7907Z"
                                fill="#743A98" />
                            <path
                                d="M39.8142 0C38.1441 0 36.78 0.489437 35.7217 1.46527C34.6634 2.44415 34.1343 3.66926 34.1343 5.14669C34.1343 6.62412 34.6634 7.79452 35.7217 8.77035C36.78 9.74923 38.1441 10.2356 39.8142 10.2356C41.4842 10.2356 42.7933 9.74619 43.8515 8.77035C44.9098 7.79452 45.439 6.5846 45.439 5.14669C45.439 3.70878 44.9098 2.44415 43.8515 1.46527C42.7933 0.489437 41.4475 0 39.8142 0Z"
                                fill="#743A98" />
                            <path
                                d="M42.099 13.4489H37.4713C36.1199 13.4489 35.0244 14.5377 35.0244 15.8809V41.9001C35.0244 43.2432 36.1199 44.3321 37.4713 44.3321H42.099C43.4504 44.3321 44.5459 43.2432 44.5459 41.9001V15.8809C44.5459 14.5377 43.4504 13.4489 42.099 13.4489Z"
                                fill="#743A98" />
                            <path
                                d="M69.2167 28.9467L78.8146 17.4343C80.136 15.8505 79.0012 13.4519 76.9305 13.4519H71.6146C70.8408 13.4519 70.1128 13.8137 69.651 14.4308L60.9737 26.0162V5.80941C60.9737 4.46574 59.8787 3.37743 58.5268 3.37743H53.8991C52.5471 3.37743 51.4521 4.46574 51.4521 5.80941V41.9031C51.4521 43.2468 52.5471 44.3351 53.8991 44.3351H58.5268C59.8787 44.3351 60.9737 43.2468 60.9737 41.9031V31.3848L69.706 43.3319C70.1679 43.9612 70.902 44.3351 71.685 44.3351H76.9856C79.0655 44.3351 80.1972 41.9183 78.8575 40.3375L69.2136 28.9498L69.2167 28.9467Z"
                                fill="#743A98" />
                            <path
                                d="M102.005 13.5401C101.152 13.7833 100.339 14.1298 99.5647 14.5828C97.8947 15.5617 96.503 16.8993 95.3897 18.5956V15.8809C95.3897 14.5372 94.2947 13.4489 92.9428 13.4489H88.3151C86.9632 13.4489 85.8682 14.5372 85.8682 15.8809V41.9001C85.8682 43.2438 86.9632 44.3321 88.3151 44.3321H92.9428C94.2947 44.3321 95.3897 43.2438 95.3897 41.9001V29.9986C95.3897 27.451 95.9831 25.6727 97.1729 24.6573C98.3596 23.6419 100.143 23.1343 102.519 23.1343H102.691C104.043 23.1343 105.138 22.046 105.138 20.7023V15.8778C105.138 14.2575 103.575 13.0932 102.009 13.5401H102.005Z"
                                fill="#743A98" />
                            <path
                                d="M138.158 13.4489H133.531C132.179 13.4489 131.084 14.5372 131.084 15.8809V17.8204C130.194 16.3825 128.921 15.2182 127.27 14.3335C125.618 13.4489 123.697 13.0051 121.507 13.0051C118.944 13.0051 116.626 13.6526 114.546 14.9415C112.466 16.2335 110.823 18.0788 109.618 20.4773C108.41 22.8759 107.808 25.6605 107.808 28.8342C107.808 32.008 108.41 34.8017 109.618 37.2185C110.823 39.6353 112.466 41.4988 114.546 42.809C116.626 44.1193 118.926 44.7729 121.452 44.7729C123.642 44.7729 125.563 44.329 127.215 43.4444C128.866 42.5598 130.157 41.3955 131.084 39.9575V41.8971C131.084 43.2407 132.179 44.329 133.531 44.329H138.158C139.51 44.329 140.605 43.2407 140.605 41.8971V15.8809C140.605 14.5372 139.51 13.4489 138.158 13.4489ZM129.105 34.4795C127.787 35.8444 126.181 36.5284 124.288 36.5284C122.394 36.5284 120.788 35.8353 119.47 34.4521C118.152 33.0689 117.494 31.1963 117.494 28.8342C117.494 26.4722 118.152 24.6178 119.47 23.2711C120.788 21.9244 122.394 21.2495 124.288 21.2495C126.181 21.2495 127.787 21.9335 129.105 23.2984C130.423 24.6634 131.081 26.5269 131.081 28.889C131.081 31.251 130.42 33.1145 129.105 34.4795Z"
                                fill="#743A98" />
                            <path
                                d="M175.55 13.4489H170.656C169.629 13.4489 168.711 14.0842 168.359 15.0449L161.82 32.7649L155.229 15.0388C154.874 14.0842 153.956 13.4489 152.935 13.4489H147.689C145.939 13.4489 144.756 15.2212 145.435 16.8233L156.645 43.2802L156.305 44.0281C154.302 48.4452 149.879 51.2876 145.004 51.2876C143.652 51.2876 142.557 52.3759 142.557 53.7195V56.568C142.557 57.9117 143.652 59 145.004 59C153.968 59 162.065 53.6679 165.561 45.4629L166.49 43.2802L177.807 16.8324C178.492 15.2273 177.309 13.4489 175.556 13.4489H175.55Z"
                                fill="#743A98" />
                        </g>
                    </svg>
                </a>
            </button>
            <nav id="nav" className={navIsOpen ? 'nav-open': ''}>
                <button class="btnMenuMobileDown btn" onClick={() => menuMobileModal(false)}>
                    <div></div>
                    <div></div>
                    <div></div>
                </button>
                <ul>
                    <li>
                        <button class="btn btnNavLi" onClick={() => setNavIsOpen(false)}>
                            <a href="#sectionBrnads">
                                <div class="li_icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <path
                                            d="M16 17V19H2V17S2 13 9 13 16 17 16 17M12.5 7.5A3.5 3.5 0 1 0 9 11A3.5 3.5 0 0 0 12.5 7.5M15.94 13A5.32 5.32 0 0 1 18 17V19H22V17S22 13.37 15.94 13M15 4A3.39 3.39 0 0 0 13.07 4.59A5 5 0 0 1 13.07 10.41A3.39 3.39 0 0 0 15 11A3.5 3.5 0 0 0 15 4Z" />
                                    </svg>
                                </div>
                                <span id="nav_1">{ t('headerText1') }</span>
                            </a>
                        </button>
                        <div class="line"></div>
                    </li>
                    <li>
                        <button class="btn btnNavLi" onClick={() => setNavIsOpen(false)}>
                            <a href="#sectionVideo">
                                <div class="li_icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <path
                                            d="M17,17H7V7H17M21,11V9H19V7C19,5.89 18.1,5 17,5H15V3H13V5H11V3H9V5H7C5.89,5 5,5.89 5,7V9H3V11H5V13H3V15H5V17A2,2 0 0,0 7,19H9V21H11V19H13V21H15V19H17A2,2 0 0,0 19,17V15H21V13H19V11M13,13H11V11H13M15,9H9V15H15V9Z" />
                                    </svg>
                                </div>
                                <span id="nav_2">{ t('headerText2') }</span>
                            </a>
                        </button>
                        <div class="line"></div>
                    </li>
                    <li>
                        <button class="btn btnNavLi" onClick={() => setNavIsOpen(false)}>
                            <a href="#sectionBenefits">
                                <div class="li_icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <path
                                            d="M15.2 10.7L16.6 16L12 12.2L7.4 16L8.8 10.8L4.6 7.3L10 7L12 2L14 7L19.4 7.3L15.2 10.7M14 19H13V16L12 15L11 16V19H10C8.9 19 8 19.9 8 21V22H16V21C16 19.9 15.11 19 14 19Z" />
                                    </svg>
                                </div>
                                <span id="nav_3">{ t('headerText3') }</span>
                            </a>
                        </button>
                        <div class="line"></div>
                    </li>
                    <li>
                        <button class="btn btnNavLi" onClick={() => setNavIsOpen(false)}>
                            <a href="#sectionConocenos">
                                <div class="li_icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <path
                                            d="M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z" />
                                    </svg>
                                </div>
                                <span id="nav_4">{ t('headerText4') }</span>
                            </a>
                        </button>
                        <div class="line"></div>
                    </li>
                    <li>
                        <button class="btn btnNavLi" onClick={() => setNavIsOpen(false)}>
                            <a href="#sectionContact">
                                <div class="li_icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <path
                                            d="M13 19C13 18.66 13.04 18.33 13.09 18H4V8L12 13L20 8V13.09C20.72 13.21 21.39 13.46 22 13.81V6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H13.09C13.04 19.67 13 19.34 13 19M20 6L12 11L4 6H20M17.75 22.16L15 19.16L16.16 18L17.75 19.59L21.34 16L22.5 17.41L17.75 22.16" />
                                    </svg>
                                </div>
                                <span id="nav_5">{ t('headerText5') }</span>
                            </a>
                        </button>
                        <div class="line"></div>
                    </li>
                    <li>
                        <button class="btn btnNavLi" onClick={() => setNavIsOpen(false)}>
                            <a href="#sectionBlog">
                                <div class="li_icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <path
                                            d="M5,3C3.89,3 3,3.89 3,5V19C3,20.11 3.89,21 5,21H19C20.11,21 21,20.11 21,19V5C21,3.89 20.11,3 19,3H5M5,5H19V19H5V5M7,7V9H17V7H7M7,11V13H17V11H7M7,15V17H14V15H7Z" />
                                    </svg>
                                </div>
                                <span id="nav_6">{ t('headerText6') }</span>
                            </a>
                        </button>
                        <div class="line"></div>
                    </li>
                    <li class="lenguage">
                        <LanguageSelector />
                    </li>
                </ul>
                <div class="containerMobileNav">
                    <div class="loboMobileNav">
                        <svg width="178" height="59" viewBox="0 0 178 59" fill="transparent">
                            <g>
                                <path
                                    d="M27.7907 5.48109H2.4469C1.09499 5.48109 0 6.56941 0 7.91308V10.6308C0 11.9745 1.09499 13.0628 2.4469 13.0628H10.3565V41.9001C10.3565 43.2438 11.4515 44.3321 12.8034 44.3321H17.4311C18.783 44.3321 19.878 43.2438 19.878 41.9001V13.0628H27.7876C29.1396 13.0628 30.2346 11.9745 30.2346 10.6308V7.91308C30.2346 6.56941 29.1396 5.48109 27.7876 5.48109H27.7907Z"
                                    fill="#743A98" />
                                <path
                                    d="M39.8142 0C38.1441 0 36.78 0.489437 35.7217 1.46527C34.6634 2.44415 34.1343 3.66926 34.1343 5.14669C34.1343 6.62412 34.6634 7.79452 35.7217 8.77035C36.78 9.74923 38.1441 10.2356 39.8142 10.2356C41.4842 10.2356 42.7933 9.74619 43.8515 8.77035C44.9098 7.79452 45.439 6.5846 45.439 5.14669C45.439 3.70878 44.9098 2.44415 43.8515 1.46527C42.7933 0.489437 41.4475 0 39.8142 0Z"
                                    fill="#743A98" />
                                <path
                                    d="M42.099 13.4489H37.4713C36.1199 13.4489 35.0244 14.5377 35.0244 15.8809V41.9001C35.0244 43.2432 36.1199 44.3321 37.4713 44.3321H42.099C43.4504 44.3321 44.5459 43.2432 44.5459 41.9001V15.8809C44.5459 14.5377 43.4504 13.4489 42.099 13.4489Z"
                                    fill="#743A98" />
                                <path
                                    d="M69.2167 28.9467L78.8146 17.4343C80.136 15.8505 79.0012 13.4519 76.9305 13.4519H71.6146C70.8408 13.4519 70.1128 13.8137 69.651 14.4308L60.9737 26.0162V5.80941C60.9737 4.46574 59.8787 3.37743 58.5268 3.37743H53.8991C52.5471 3.37743 51.4521 4.46574 51.4521 5.80941V41.9031C51.4521 43.2468 52.5471 44.3351 53.8991 44.3351H58.5268C59.8787 44.3351 60.9737 43.2468 60.9737 41.9031V31.3848L69.706 43.3319C70.1679 43.9612 70.902 44.3351 71.685 44.3351H76.9856C79.0655 44.3351 80.1972 41.9183 78.8575 40.3375L69.2136 28.9498L69.2167 28.9467Z"
                                    fill="#743A98" />
                                <path
                                    d="M102.005 13.5401C101.152 13.7833 100.339 14.1298 99.5647 14.5828C97.8947 15.5617 96.503 16.8993 95.3897 18.5956V15.8809C95.3897 14.5372 94.2947 13.4489 92.9428 13.4489H88.3151C86.9632 13.4489 85.8682 14.5372 85.8682 15.8809V41.9001C85.8682 43.2438 86.9632 44.3321 88.3151 44.3321H92.9428C94.2947 44.3321 95.3897 43.2438 95.3897 41.9001V29.9986C95.3897 27.451 95.9831 25.6727 97.1729 24.6573C98.3596 23.6419 100.143 23.1343 102.519 23.1343H102.691C104.043 23.1343 105.138 22.046 105.138 20.7023V15.8778C105.138 14.2575 103.575 13.0932 102.009 13.5401H102.005Z"
                                    fill="#743A98" />
                                <path
                                    d="M138.158 13.4489H133.531C132.179 13.4489 131.084 14.5372 131.084 15.8809V17.8204C130.194 16.3825 128.921 15.2182 127.27 14.3335C125.618 13.4489 123.697 13.0051 121.507 13.0051C118.944 13.0051 116.626 13.6526 114.546 14.9415C112.466 16.2335 110.823 18.0788 109.618 20.4773C108.41 22.8759 107.808 25.6605 107.808 28.8342C107.808 32.008 108.41 34.8017 109.618 37.2185C110.823 39.6353 112.466 41.4988 114.546 42.809C116.626 44.1193 118.926 44.7729 121.452 44.7729C123.642 44.7729 125.563 44.329 127.215 43.4444C128.866 42.5598 130.157 41.3955 131.084 39.9575V41.8971C131.084 43.2407 132.179 44.329 133.531 44.329H138.158C139.51 44.329 140.605 43.2407 140.605 41.8971V15.8809C140.605 14.5372 139.51 13.4489 138.158 13.4489ZM129.105 34.4795C127.787 35.8444 126.181 36.5284 124.288 36.5284C122.394 36.5284 120.788 35.8353 119.47 34.4521C118.152 33.0689 117.494 31.1963 117.494 28.8342C117.494 26.4722 118.152 24.6178 119.47 23.2711C120.788 21.9244 122.394 21.2495 124.288 21.2495C126.181 21.2495 127.787 21.9335 129.105 23.2984C130.423 24.6634 131.081 26.5269 131.081 28.889C131.081 31.251 130.42 33.1145 129.105 34.4795Z"
                                    fill="#743A98" />
                                <path
                                    d="M175.55 13.4489H170.656C169.629 13.4489 168.711 14.0842 168.359 15.0449L161.82 32.7649L155.229 15.0388C154.874 14.0842 153.956 13.4489 152.935 13.4489H147.689C145.939 13.4489 144.756 15.2212 145.435 16.8233L156.645 43.2802L156.305 44.0281C154.302 48.4452 149.879 51.2876 145.004 51.2876C143.652 51.2876 142.557 52.3759 142.557 53.7195V56.568C142.557 57.9117 143.652 59 145.004 59C153.968 59 162.065 53.6679 165.561 45.4629L166.49 43.2802L177.807 16.8324C178.492 15.2273 177.309 13.4489 175.556 13.4489H175.55Z"
                                    fill="#743A98" />
                            </g>
                        </svg>
                    </div>
                    <div class="socialNetworksMobile">
                        <button class="socialNetworksMobile_item btn" id="copyEmailMobile" onClick={handleEmailCopyMobile}>
                            <div class="nav_icon_mobile">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path
                                        d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6M20 6L12 11L4 6H20M20 18H4V8L12 13L20 8V18Z" />
                                </svg>
                            </div>
                            <span>comercial@mytikray.com</span>
                        </button>
                        <button class="socialNetworksMobile_item btn" id="copyPhoneMobile" onClick={handlePhoneCopyMobile}>
                            <div class="nav_icon_mobile">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <title>phone</title>
                                    <path
                                        d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z" />
                                </svg>
                            </div>
                            <span>+51 905 434 422</span>
                        </button>
                    </div>
                </div>
            </nav>
            <button class="btnMenuMobileUp btn" onClick={() => menuMobileModal(true)}>
                <div></div>
                <div></div>
                <div></div>
            </button>
        </header>
    )
}

export default TikrayHeader;